

@import node_modules/bootstrap/scss/functions
@import node_modules/bootstrap/scss/variables
@import node_modules/bootstrap/scss/mixins/_breakpoints

@include media-breakpoint-down(lg) 
	.xl-pad-xs
		padding:$size-xs
	.xl-pad-s
		padding:$size-s
	.xl-pad-m
		padding:$size-m
	.xl-pad-l
		padding:$size-l
	.xl-pad-xl
		padding:$size-xl
	.xl-pad-xxl
		padding:$size-xxl

	.xl-pad-bottom-xs
		padding-bottom:$size-xs
	.xl-pad-bottom-s
		padding-bottom:$size-s
	.xl-pad-bottom-m
		padding-bottom:$size-m
	.xl-pad-bottom-l
		padding-bottom:$size-l
	.xl-pad-bottom-xl
		padding-bottom:$size-xl
	.xl-pad-bottom-xxl
		padding-bottom:$size-xxl

	.xl-marg-xs
		margin:$size-xs
	.xl-marg-s
		margin:$size-s
	.xl-marg-m
		margin:$size-m
	.xl-marg-l
		margin:$size-l
	.xl-marg-xl
		margin:$size-xl
	.xl-marg-xxl
		margin:$size-xxl

	.xl-marg-top-xs
		margin-top:$size-xs
	.xl-marg-top-s
		margin-top:$size-s
	.xl-marg-top-m
		margin-top:$size-m
	.xl-marg-top-l
		margin-top:$size-l
	.xl-marg-top-xl
		// border:3px solid red
		margin-top:$size-xl
	.xl-marg-top-xxl
		margin-top:$size-xxl	

	.xl-marg-bottom-xs
		margin-bottom:$size-xs
	.xl-marg-bottom-s
		margin-bottom:$size-s
	.xl-marg-bottom-m
		margin-bottom:$size-m
	.xl-marg-bottom-l
		margin-bottom:$size-l
	.xl-marg-bottom-xl
		margin-bottom:$size-xl
	.xl-marg-bottom-xxl
		margin-bottom:$size-xxl

	.xl-marg-left-xs
		margin-left:$size-xs
	.xl-marg-left-s
		margin-left:$size-s
	.xl-marg-left-m
		margin-left:$size-m
	.xl-marg-left-l
		margin-left:$size-l
	.xl-marg-left-xl
		margin-left:$size-xl
	.xl-marg-left-xxl
		margin-left:$size-xxl

	.xl-marg-right-xs
		margin-right:$size-xs
	.xl-marg-right-s
		margin-right:$size-s
	.xl-marg-right-m
		margin-right:$size-m
	.xl-marg-right-l
		margin-right:$size-l
	.xl-marg-right-xl
		margin-right:$size-xl
	.xl-marg-right-xxl
		margin-right:$size-xxl






@include media-breakpoint-down(md) 
	.md-pad-xs
		padding:$size-xs
	.md-pad-s
		padding:$size-s
	.md-pad-m
		padding:$size-m
	.md-pad-l
		padding:$size-l
	.md-pad-xl
		padding:$size-xl
	.md-pad-xxl
		padding:$size-xxl

	.md-pad-bottom-xs
		padding-bottom:$size-xs
	.md-pad-bottom-s
		padding-bottom:$size-s
	.md-pad-bottom-m
		padding-bottom:$size-m
	.md-pad-bottom-l
		padding-bottom:$size-l
	.md-pad-bottom-xl
		padding-bottom:$size-xl
	.md-pad-bottom-xxl
		padding-bottom:$size-xxl

	.md-marg-xs
		margin:$size-xs
	.md-marg-s
		margin:$size-s
	.md-marg-m
		margin:$size-m
	.md-marg-l
		margin:$size-l
	.md-marg-xl
		margin:$size-xl
	.md-marg-xxl
		margin:$size-xxl

	.md-marg-top-xs
		margin-top:$size-xs
	.md-marg-top-s
		margin-top:$size-s
	.md-marg-top-m
		margin-top:$size-m
	.md-marg-top-l
		margin-top:$size-l
	.md-marg-top-xl
		// border:3px solid red
		margin-top:$size-xl
	.md-marg-top-xxl
		margin-top:$size-xxl	

	.md-marg-bottom-xs
		margin-bottom:$size-xs
	.md-marg-bottom-s
		margin-bottom:$size-s
	.md-marg-bottom-m
		margin-bottom:$size-m
	.md-marg-bottom-l
		margin-bottom:$size-l
	.md-marg-bottom-xl
		margin-bottom:$size-xl
	.md-marg-bottom-xxl
		margin-bottom:$size-xxl

	.md-marg-left-xs
		margin-left:$size-xs
	.md-marg-left-s
		margin-left:$size-s
	.md-marg-left-m
		margin-left:$size-m
	.md-marg-left-l
		margin-left:$size-l
	.md-marg-left-xl
		margin-left:$size-xl
	.md-marg-left-xxl
		margin-left:$size-xxl

	.md-marg-right-xs
		margin-right:$size-xs
	.md-marg-right-s
		margin-right:$size-s
	.md-marg-right-m
		margin-right:$size-m
	.md-marg-right-l
		margin-right:$size-l
	.md-marg-right-xl
		margin-right:$size-xl
	.md-marg-right-xxl
		margin-right:$size-xxl





@include media-breakpoint-down(sm) 
	.sm-pad-xs
		padding:$size-xs
	.sm-pad-s
		padding:$size-s
	.sm-pad-m
		padding:$size-m
	.sm-pad-l
		padding:$size-l
	.sm-pad-xl
		padding:$size-xl
	.sm-pad-xxl
		padding:$size-xxl

	.sm-pad-bottom-xs
		padding-bottom:$size-xs
	.sm-pad-bottom-s
		padding-bottom:$size-s
	.sm-pad-bottom-m
		padding-bottom:$size-m
	.sm-pad-bottom-l
		padding-bottom:$size-l
	.sm-pad-bottom-xl
		padding-bottom:$size-xl
	.sm-pad-bottom-xxl
		padding-bottom:$size-xxl

	.sm-marg-xs
		margin:$size-xs
	.sm-marg-s
		margin:$size-s
	.sm-marg-m
		margin:$size-m
	.sm-marg-l
		margin:$size-l
	.sm-marg-xl
		margin:$size-xl
	.sm-marg-xxl
		margin:$size-xxl

	.sm-marg-top-xs
		margin-top:$size-xs
	.sm-marg-top-s
		margin-top:$size-s
	.sm-marg-top-m
		margin-top:$size-m
	.sm-marg-top-l
		margin-top:$size-l
	.sm-marg-top-xl
		// border:3px solid red
		margin-top:$size-xl
	.sm-marg-top-xxl
		margin-top:$size-xxl	

	.sm-marg-bottom-xs
		margin-bottom:$size-xs
	.sm-marg-bottom-s
		margin-bottom:$size-s
	.sm-marg-bottom-m
		margin-bottom:$size-m
	.sm-marg-bottom-l
		margin-bottom:$size-l
	.sm-marg-bottom-xl
		margin-bottom:$size-xl
	.sm-marg-bottom-xxl
		margin-bottom:$size-xxl

	.sm-marg-left-xs
		margin-left:$size-xs
	.sm-marg-left-s
		margin-left:$size-s
	.sm-marg-left-m
		margin-left:$size-m
	.sm-marg-left-l
		margin-left:$size-l
	.sm-marg-left-xl
		margin-left:$size-xl
	.sm-marg-left-xxl
		margin-left:$size-xxl

	.sm-marg-right-xs
		margin-right:$size-xs
	.sm-marg-right-s
		margin-right:$size-s
	.sm-marg-right-m
		margin-right:$size-m
	.sm-marg-right-l
		margin-right:$size-l
	.sm-marg-right-xl
		margin-right:$size-xl
	.sm-marg-right-xxl
		margin-right:$size-xxl




@include media-breakpoint-down(xs) 
	.xs-pad-xs
		padding:$size-xs
	.xs-pad-s
		padding:$size-s
	.xs-pad-m
		padding:$size-m
	.xs-pad-l
		padding:$size-l
	.xs-pad-xl
		padding:$size-xl
	.xs-pad-xxl
		padding:$size-xxl

	.xs-pad-bottom-xs
		padding-bottom:$size-xs
	.xs-pad-bottom-s
		padding-bottom:$size-s
	.xs-pad-bottom-m
		padding-bottom:$size-m
	.xs-pad-bottom-l
		padding-bottom:$size-l
	.xs-pad-bottom-xl
		padding-bottom:$size-xl
	.xs-pad-bottom-xxl
		padding-bottom:$size-xxl

	.xs-marg-xs
		margin:$size-xs
	.xs-marg-s
		margin:$size-s
	.xs-marg-m
		margin:$size-m
	.xs-marg-l
		margin:$size-l
	.xs-marg-xl
		margin:$size-xl
	.xs-marg-xxl
		margin:$size-xxl

	.xs-marg-top-xs
		margin-top:$size-xs
	.xs-marg-top-s
		margin-top:$size-s
	.xs-marg-top-m
		margin-top:$size-m
	.xs-marg-top-l
		margin-top:$size-l
	.xs-marg-top-xl
		// border:3px solid red
		margin-top:$size-xl
	.xs-marg-top-xxl
		margin-top:$size-xxl	

	.xs-marg-bottom-xs
		margin-bottom:$size-xs
	.xs-marg-bottom-s
		margin-bottom:$size-s
	.xs-marg-bottom-m
		margin-bottom:$size-m
	.xs-marg-bottom-l
		margin-bottom:$size-l
	.xs-marg-bottom-xl
		margin-bottom:$size-xl
	.xs-marg-bottom-xxl
		margin-bottom:$size-xxl

	.xs-marg-left-xs
		margin-left:$size-xs
	.xs-marg-left-s
		margin-left:$size-s
	.xs-marg-left-m
		margin-left:$size-m
	.xs-marg-left-l
		margin-left:$size-l
	.xs-marg-left-xl
		margin-left:$size-xl
	.xs-marg-left-xxl
		margin-left:$size-xxl

	.xs-marg-right-xs
		margin-right:$size-xs
	.xs-marg-right-s
		margin-right:$size-s
	.xs-marg-right-m
		margin-right:$size-m
	.xs-marg-right-l
		margin-right:$size-l
	.xs-marg-right-xl
		margin-right:$size-xl
	.xs-marg-right-xxl
		margin-right:$size-xxl