.color-brightest, .color-brightest:hover, .color-brightest:visited, .color-brightest:active {
  color: #f1c8a6;
}

.color-brighter, .color-brighter:hover, .color-brighter:visited, .color-brighter:active {
  color: #d67b58;
}

.color-bright, .color-bright:hover, .color-bright:visited, .color-bright:active {
  color: #c54b2d;
}

.color-medium, .color-medium:hover, .color-medium:visited, .color-medium:active {
  color: #AA2611;
}

.color-dark, .color-dark:hover, .color-dark:visited, .color-dark:active {
  color: #812112;
}

.color-darker, .color-darker:hover, .color-darker:visited, .color-darker:active {
  color: #4e160e;
}

.color-darkest, .color-darkest:hover, .color-darkest:visited, .color-darkest:active {
  color: #1d0805;
}

.color-grey-brightest, .color-grey-brightest:hover, .color-grey-brightest:visited, .color-grey-brightest:active {
  color: #eee;
}

.color-grey-brighter, .color-grey-brighter:hover, .color-grey-brighter:visited, .color-grey-brighter:active {
  color: #ddd;
}

.color-grey-bright, .color-grey-bright:hover, .color-grey-bright:visited, .color-grey-bright:active {
  color: #ccc;
}

.color-grey-medium, .color-grey-medium:hover, .color-grey-medium:visited, .color-grey-medium:active {
  color: #999;
}

.color-grey-dark, .color-grey-dark:hover, .color-grey-dark:visited, .color-grey-dark:active {
  color: #666;
}

.color-grey-darker, .color-grey-darker:hover, .color-grey-darker:visited, .color-grey-darker:active {
  color: #141414;
}

.color-black {
  color: black;
}

.color-grey {
  color: #999;
}

.color-white {
  color: white;
}

.bkg-color-brightest {
  background-color: #f1c8a6;
}

.bkg-color-brighter {
  background-color: #d67b58;
}

.bkg-color-bright {
  background-color: #c54b2d;
}

.bkg-color-medium {
  background-color: #AA2611;
}

.bkg-color-dark {
  background-color: #812112;
}

.bkg-color-darker {
  background-color: #4e160e;
}

.bkg-color-darkest {
  background-color: #1d0805;
}

.bkg-color-black {
  background-color: black;
}

.bkg-color-white {
  background-color: white;
}

.bkg-color-grey {
  background-color: #999;
}

.bkg-color-grey-brightest {
  background-color: #eee;
}

.bkg-gradient-brighter-to-brightest {
  background: linear-gradient(0deg, #f1c8a6 0%, #d67b58 100%);
}

.bkg-gradient-brighter-to-white {
  background: linear-gradient(0deg, white 0%, #d67b58 100%);
}

.bkg-gradient-white-to-brighter {
  background: linear-gradient(0deg, #d67b58 0%, white 100%);
}

.width-100, header .container .row {
  width: 100%;
}

.width-75 {
  width: 75%;
}

.width-50 {
  width: 50%;
}

.width-25 {
  width: 25%;
}

.pad-xs {
  padding: 5px;
}

.pad-s {
  padding: 10px;
}

.pad-m {
  padding: 15px;
}

.pad-l {
  padding: 20px;
}

.pad-xl {
  padding: 30px;
}

.pad-xxl {
  padding: 60px;
}

.pad-top-xs {
  padding-top: 5px;
}

.pad-top-s {
  padding-top: 10px;
}

.pad-top-m {
  padding-top: 15px;
}

.pad-top-l {
  padding-top: 20px;
}

.pad-top-xl {
  padding-top: 30px;
}

.pad-top-xxl {
  padding-top: 60px;
}

.pad-bottom-xs {
  padding-bottom: 5px;
}

.pad-bottom-s {
  padding-bottom: 10px;
}

.pad-bottom-m {
  padding-bottom: 15px;
}

.pad-bottom-l {
  padding-bottom: 20px;
}

.pad-bottom-xl {
  padding-bottom: 30px;
}

.pad-bottom-xxl {
  padding-bottom: 60px;
}

.marg-xs {
  margin: 5px;
}

.marg-s {
  margin: 10px;
}

.marg-m {
  margin: 15px;
}

.marg-l {
  margin: 20px;
}

.marg-xl {
  margin: 30px;
}

.marg-xxl {
  margin: 60px;
}

.marg-bottom-xs {
  margin-bottom: 5px;
}

.marg-bottom-s {
  margin-bottom: 10px;
}

.marg-bottom-m {
  margin-bottom: 15px;
}

.marg-bottom-l {
  margin-bottom: 20px;
}

.marg-bottom-xl {
  margin-bottom: 30px;
}

.marg-bottom-xxl {
  margin-bottom: 60px;
}

.marg-left-xs {
  margin-left: 5px;
}

.marg-left-s {
  margin-left: 10px;
}

.marg-left-m {
  margin-left: 15px;
}

.marg-left-l {
  margin-left: 20px;
}

.marg-left-xl {
  margin-left: 30px;
}

.marg-left-xxl {
  margin-left: 60px;
}

.marg-right-xs {
  margin-right: 5px;
}

.marg-right-s {
  margin-right: 10px;
}

.marg-right-m {
  margin-right: 15px;
}

.marg-right-l {
  margin-right: 20px;
}

.marg-right-xl {
  margin-right: 30px;
}

.marg-right-xxl {
  margin-right: 60px;
}

@media (max-width: 1199.98px) {
  .xl-pad-xs {
    padding: 5px;
  }
  .xl-pad-s {
    padding: 10px;
  }
  .xl-pad-m {
    padding: 15px;
  }
  .xl-pad-l {
    padding: 20px;
  }
  .xl-pad-xl {
    padding: 30px;
  }
  .xl-pad-xxl {
    padding: 60px;
  }
  .xl-pad-bottom-xs {
    padding-bottom: 5px;
  }
  .xl-pad-bottom-s {
    padding-bottom: 10px;
  }
  .xl-pad-bottom-m {
    padding-bottom: 15px;
  }
  .xl-pad-bottom-l {
    padding-bottom: 20px;
  }
  .xl-pad-bottom-xl {
    padding-bottom: 30px;
  }
  .xl-pad-bottom-xxl {
    padding-bottom: 60px;
  }
  .xl-marg-xs {
    margin: 5px;
  }
  .xl-marg-s {
    margin: 10px;
  }
  .xl-marg-m {
    margin: 15px;
  }
  .xl-marg-l {
    margin: 20px;
  }
  .xl-marg-xl {
    margin: 30px;
  }
  .xl-marg-xxl {
    margin: 60px;
  }
  .xl-marg-top-xs {
    margin-top: 5px;
  }
  .xl-marg-top-s {
    margin-top: 10px;
  }
  .xl-marg-top-m {
    margin-top: 15px;
  }
  .xl-marg-top-l {
    margin-top: 20px;
  }
  .xl-marg-top-xl {
    margin-top: 30px;
  }
  .xl-marg-top-xxl {
    margin-top: 60px;
  }
  .xl-marg-bottom-xs {
    margin-bottom: 5px;
  }
  .xl-marg-bottom-s {
    margin-bottom: 10px;
  }
  .xl-marg-bottom-m {
    margin-bottom: 15px;
  }
  .xl-marg-bottom-l {
    margin-bottom: 20px;
  }
  .xl-marg-bottom-xl {
    margin-bottom: 30px;
  }
  .xl-marg-bottom-xxl {
    margin-bottom: 60px;
  }
  .xl-marg-left-xs {
    margin-left: 5px;
  }
  .xl-marg-left-s {
    margin-left: 10px;
  }
  .xl-marg-left-m {
    margin-left: 15px;
  }
  .xl-marg-left-l {
    margin-left: 20px;
  }
  .xl-marg-left-xl {
    margin-left: 30px;
  }
  .xl-marg-left-xxl {
    margin-left: 60px;
  }
  .xl-marg-right-xs {
    margin-right: 5px;
  }
  .xl-marg-right-s {
    margin-right: 10px;
  }
  .xl-marg-right-m {
    margin-right: 15px;
  }
  .xl-marg-right-l {
    margin-right: 20px;
  }
  .xl-marg-right-xl {
    margin-right: 30px;
  }
  .xl-marg-right-xxl {
    margin-right: 60px;
  }
}
@media (max-width: 991.98px) {
  .md-pad-xs {
    padding: 5px;
  }
  .md-pad-s {
    padding: 10px;
  }
  .md-pad-m {
    padding: 15px;
  }
  .md-pad-l {
    padding: 20px;
  }
  .md-pad-xl {
    padding: 30px;
  }
  .md-pad-xxl {
    padding: 60px;
  }
  .md-pad-bottom-xs {
    padding-bottom: 5px;
  }
  .md-pad-bottom-s {
    padding-bottom: 10px;
  }
  .md-pad-bottom-m {
    padding-bottom: 15px;
  }
  .md-pad-bottom-l {
    padding-bottom: 20px;
  }
  .md-pad-bottom-xl {
    padding-bottom: 30px;
  }
  .md-pad-bottom-xxl {
    padding-bottom: 60px;
  }
  .md-marg-xs {
    margin: 5px;
  }
  .md-marg-s {
    margin: 10px;
  }
  .md-marg-m {
    margin: 15px;
  }
  .md-marg-l {
    margin: 20px;
  }
  .md-marg-xl {
    margin: 30px;
  }
  .md-marg-xxl {
    margin: 60px;
  }
  .md-marg-top-xs {
    margin-top: 5px;
  }
  .md-marg-top-s {
    margin-top: 10px;
  }
  .md-marg-top-m {
    margin-top: 15px;
  }
  .md-marg-top-l {
    margin-top: 20px;
  }
  .md-marg-top-xl {
    margin-top: 30px;
  }
  .md-marg-top-xxl {
    margin-top: 60px;
  }
  .md-marg-bottom-xs {
    margin-bottom: 5px;
  }
  .md-marg-bottom-s {
    margin-bottom: 10px;
  }
  .md-marg-bottom-m {
    margin-bottom: 15px;
  }
  .md-marg-bottom-l {
    margin-bottom: 20px;
  }
  .md-marg-bottom-xl {
    margin-bottom: 30px;
  }
  .md-marg-bottom-xxl {
    margin-bottom: 60px;
  }
  .md-marg-left-xs {
    margin-left: 5px;
  }
  .md-marg-left-s {
    margin-left: 10px;
  }
  .md-marg-left-m {
    margin-left: 15px;
  }
  .md-marg-left-l {
    margin-left: 20px;
  }
  .md-marg-left-xl {
    margin-left: 30px;
  }
  .md-marg-left-xxl {
    margin-left: 60px;
  }
  .md-marg-right-xs {
    margin-right: 5px;
  }
  .md-marg-right-s {
    margin-right: 10px;
  }
  .md-marg-right-m {
    margin-right: 15px;
  }
  .md-marg-right-l {
    margin-right: 20px;
  }
  .md-marg-right-xl {
    margin-right: 30px;
  }
  .md-marg-right-xxl {
    margin-right: 60px;
  }
}
@media (max-width: 767.98px) {
  .sm-pad-xs {
    padding: 5px;
  }
  .sm-pad-s {
    padding: 10px;
  }
  .sm-pad-m {
    padding: 15px;
  }
  .sm-pad-l {
    padding: 20px;
  }
  .sm-pad-xl {
    padding: 30px;
  }
  .sm-pad-xxl {
    padding: 60px;
  }
  .sm-pad-bottom-xs {
    padding-bottom: 5px;
  }
  .sm-pad-bottom-s {
    padding-bottom: 10px;
  }
  .sm-pad-bottom-m {
    padding-bottom: 15px;
  }
  .sm-pad-bottom-l {
    padding-bottom: 20px;
  }
  .sm-pad-bottom-xl {
    padding-bottom: 30px;
  }
  .sm-pad-bottom-xxl {
    padding-bottom: 60px;
  }
  .sm-marg-xs {
    margin: 5px;
  }
  .sm-marg-s {
    margin: 10px;
  }
  .sm-marg-m {
    margin: 15px;
  }
  .sm-marg-l {
    margin: 20px;
  }
  .sm-marg-xl {
    margin: 30px;
  }
  .sm-marg-xxl {
    margin: 60px;
  }
  .sm-marg-top-xs {
    margin-top: 5px;
  }
  .sm-marg-top-s {
    margin-top: 10px;
  }
  .sm-marg-top-m {
    margin-top: 15px;
  }
  .sm-marg-top-l {
    margin-top: 20px;
  }
  .sm-marg-top-xl {
    margin-top: 30px;
  }
  .sm-marg-top-xxl {
    margin-top: 60px;
  }
  .sm-marg-bottom-xs {
    margin-bottom: 5px;
  }
  .sm-marg-bottom-s {
    margin-bottom: 10px;
  }
  .sm-marg-bottom-m {
    margin-bottom: 15px;
  }
  .sm-marg-bottom-l {
    margin-bottom: 20px;
  }
  .sm-marg-bottom-xl {
    margin-bottom: 30px;
  }
  .sm-marg-bottom-xxl {
    margin-bottom: 60px;
  }
  .sm-marg-left-xs {
    margin-left: 5px;
  }
  .sm-marg-left-s {
    margin-left: 10px;
  }
  .sm-marg-left-m {
    margin-left: 15px;
  }
  .sm-marg-left-l {
    margin-left: 20px;
  }
  .sm-marg-left-xl {
    margin-left: 30px;
  }
  .sm-marg-left-xxl {
    margin-left: 60px;
  }
  .sm-marg-right-xs {
    margin-right: 5px;
  }
  .sm-marg-right-s {
    margin-right: 10px;
  }
  .sm-marg-right-m {
    margin-right: 15px;
  }
  .sm-marg-right-l {
    margin-right: 20px;
  }
  .sm-marg-right-xl {
    margin-right: 30px;
  }
  .sm-marg-right-xxl {
    margin-right: 60px;
  }
}
@media (max-width: 575.98px) {
  .xs-pad-xs {
    padding: 5px;
  }
  .xs-pad-s {
    padding: 10px;
  }
  .xs-pad-m {
    padding: 15px;
  }
  .xs-pad-l {
    padding: 20px;
  }
  .xs-pad-xl {
    padding: 30px;
  }
  .xs-pad-xxl {
    padding: 60px;
  }
  .xs-pad-bottom-xs {
    padding-bottom: 5px;
  }
  .xs-pad-bottom-s {
    padding-bottom: 10px;
  }
  .xs-pad-bottom-m {
    padding-bottom: 15px;
  }
  .xs-pad-bottom-l {
    padding-bottom: 20px;
  }
  .xs-pad-bottom-xl {
    padding-bottom: 30px;
  }
  .xs-pad-bottom-xxl {
    padding-bottom: 60px;
  }
  .xs-marg-xs {
    margin: 5px;
  }
  .xs-marg-s {
    margin: 10px;
  }
  .xs-marg-m {
    margin: 15px;
  }
  .xs-marg-l {
    margin: 20px;
  }
  .xs-marg-xl {
    margin: 30px;
  }
  .xs-marg-xxl {
    margin: 60px;
  }
  .xs-marg-top-xs {
    margin-top: 5px;
  }
  .xs-marg-top-s {
    margin-top: 10px;
  }
  .xs-marg-top-m {
    margin-top: 15px;
  }
  .xs-marg-top-l {
    margin-top: 20px;
  }
  .xs-marg-top-xl {
    margin-top: 30px;
  }
  .xs-marg-top-xxl {
    margin-top: 60px;
  }
  .xs-marg-bottom-xs {
    margin-bottom: 5px;
  }
  .xs-marg-bottom-s {
    margin-bottom: 10px;
  }
  .xs-marg-bottom-m {
    margin-bottom: 15px;
  }
  .xs-marg-bottom-l {
    margin-bottom: 20px;
  }
  .xs-marg-bottom-xl {
    margin-bottom: 30px;
  }
  .xs-marg-bottom-xxl {
    margin-bottom: 60px;
  }
  .xs-marg-left-xs {
    margin-left: 5px;
  }
  .xs-marg-left-s {
    margin-left: 10px;
  }
  .xs-marg-left-m {
    margin-left: 15px;
  }
  .xs-marg-left-l {
    margin-left: 20px;
  }
  .xs-marg-left-xl {
    margin-left: 30px;
  }
  .xs-marg-left-xxl {
    margin-left: 60px;
  }
  .xs-marg-right-xs {
    margin-right: 5px;
  }
  .xs-marg-right-s {
    margin-right: 10px;
  }
  .xs-marg-right-m {
    margin-right: 15px;
  }
  .xs-marg-right-l {
    margin-right: 20px;
  }
  .xs-marg-right-xl {
    margin-right: 30px;
  }
  .xs-marg-right-xxl {
    margin-right: 60px;
  }
}
body {
  font-family: "Open Sans", "sans-serif" !important;
}

h1, h2, h3, h4 {
  font-family: "Raleway", sans-serif !important;
}

h1, h2, h3 {
  user-select: none;
}

.text-xs {
  font-size: 0.8rem;
}

.text-s {
  font-size: 0.9rem;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.text-drop-shadow {
  text-shadow: 0 0 10px #000;
}

.text-discrete {
  font-size: 0.6rem;
  color: #ccc;
}

ul.alert li {
  list-style: none;
}

.center-vertically, header .container {
  display: flex;
  align-items: center;
}

.center, .languages ul li a {
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.languages {
  min-width: 60px;
  text-align: right;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
}
.languages ul {
  padding: 0;
  margin: 0;
}
.languages ul li {
  display: inline-block;
  list-style: none;
}
.languages ul li a {
  width: 30px;
  height: 30px;
  color: #d67b58;
  display: block;
  text-align: center;
}
.languages ul li a:hover {
  text-decoration: none;
}
.languages ul li a.active {
  border-bottom: 1px solid #d67b58;
}
.languages ul li a.active:hover {
  pointer: default !important;
}
.languages ul li a:not(.active):hover {
  border-bottom: 1px solid #AA2611;
  color: #f1c8a6;
}

a, a:hover {
  color: #AA2611;
}
a.hover-opacify {
  transition: opacity 400ms;
}
a.hover-opacify:hover {
  opacity: 0.5;
}

main {
  overflow: hidden;
}

.logo img {
  width: 200px;
}

.round img {
  border-radius: 100%;
}

h1 {
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0;
}

.multiply {
  mix-blend-mode: multiply;
}

*:disabled {
  cursor: not-allowed;
}

.cv-pdf {
  float: left;
}
.cv-pdf a img {
  width: 70px;
  opacity: 0.4;
}

.footer {
  color: #d67b58;
  font-size: 0.9rem;
}
.footer * {
  font-family: open-light, sans-serif;
}

header {
  background-size: cover;
  background-color: #812112;
  background-blend-mode: multiply;
}
header .container {
  min-height: 35vh;
}
header .container .row .col .navbar {
  padding: 0;
}
header .container .row .col .navbar .navbar-collapse {
  justify-content: space-between;
}
header .container .row .col .navbar .navbar-collapse a.nav-link {
  white-space: nowrap;
  min-width: 73px;
  transition: color 400ms;
  color: #d67b58 !important;
  text-align: right;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
header .container .row .col .navbar .navbar-collapse a.nav-link:hover {
  color: #f1c8a6 !important;
}

#projects {
  position: relative;
}
#projects #projectZoomed {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
}
#projects #projectZoomed img {
  width: 90vw;
}
#projects #projectZoomModal img {
  width: 100%;
}
@media (min-width: 768px) {
  #projects .modal-xl {
    max-width: 60vw;
  }
}

.logo-clients .logo-client {
  mix-blend-mode: multiply;
}
.logo-clients .logo-client a {
  opacity: 0.5;
  transition: opacity 400ms;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-clients .logo-client a:hover {
  opacity: 0.8;
}

#tools .tools-list {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
}
#tools .tools-list h5 {
  margin: 0;
}
#tools .tools-list p {
  font-family: open-light, sans-serif;
  margin: 0;
  color: #999;
}

address {
  float: left;
}

.LI-badge-container.horizontal.light {
  width: 100% !important;
}

label {
  display: none;
}

.contact-form-status {
  font-size: 14px;
}

footer nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
footer nav ul li {
  list-style: none;
}
footer nav ul li a {
  color: #d67b58;
  transition: 400ms color;
}
footer nav ul li a:hover {
  color: #f1c8a6 !important;
}

.mat-accordion, .mat-accordion .mat-expansion-panel {
  background: transparent;
}
.mat-accordion .mat-expansion-panel {
  border: 1px solid #ddd;
}

.mat-dialog-container {
  position: relative;
}
.mat-dialog-container span.close-cross {
  position: absolute;
  right: 10px;
  top: 10px;
}
.mat-dialog-container span.close-cross button, .mat-dialog-container span.close-cross button:active, .mat-dialog-container span.close-cross button:hover, .mat-dialog-container span.close-cross button:focus, .mat-dialog-container span.close-cross button::focus, .mat-dialog-container span.close-cross button:visited {
  background: transparent;
  border: 0;
}
.mat-dialog-container .mat-dialog-content {
  max-height: 77vh !important;
}
.mat-dialog-container .mat-dialog-content .img-container {
  display: flex;
  justify-content: center;
}
.mat-dialog-container .mat-dialog-content .img-container img {
  width: 90vmin;
  max-width: 100%;
  height: min-intrinsic;
}

@media (max-width: 575.98px) {
  header .container {
    min-height: 160px;
  }
}
@media (max-width: 1199.98px) {
  #projects .grid {
    grid-template-areas: "myArea1 myArea1 myArea2" "myArea3 myArea4 myArea4" "myArea3 myArea5 myArea6" "myArea7 myArea8 myArea6" "myArea9 myArea10 myArea10" "myArea11 myArea12 myArea13" "myArea14 myArea14 myArea15" "myArea16 myArea16 myArea15  ";
  }
}
@media (min-width: 992px) {
  #projects .grid {
    grid-template-areas: "myArea1 myArea1 myArea2 myArea3 " "myArea4 myArea5 myArea2 myArea6 " "myArea4 myArea7 myArea7 myArea8 " "myArea9 myArea10 myArea10 myArea10 " "myArea11 myArea11 myArea13 myArea14 " "myArea15 myArea12 myArea13 myArea14 " "myArea15 myArea12 myArea16 myArea16  ";
  }
}
@media (min-width: 1200px) {
  .text-not-so-wide-on-large-screen {
    max-width: 70%;
  }
  #projects .grid {
    grid-template-areas: "myArea1 myArea1 myArea2 myArea3 myArea4" "myArea5 myArea6 myArea7 myArea3 myArea8" "myArea9 myArea10 myArea11 myArea11 myArea12" "myArea13 myArea14 myArea15 myArea16 myArea12";
  }
}