.languages 
	$language_box_size : $size-xl
	min-width: $language_box_size * 2
	text-align: right
	height: 100%
	display: flex
	align-items: center
	flex-flow: row-reverse
	ul
		padding: 0
		margin:0
		li
			display: inline-block
			list-style: none
			
			a
				width: $language_box_size
				height: $language_box_size
				@extend .center
				color:$color-brighter
				// padding: $size-s
				display: block
				// min-width: $size-xl
				// min-height:$size-xl
				text-align: center
				&:hover 
					text-decoration: none
				&.active 
					border-bottom:1px solid $color-brighter
					&:hover
						pointer: default !important
				&:not(.active) 
					&:hover 
						// background-color: $color-medium
						border-bottom:1px solid $color-medium
						color:$color-brightest