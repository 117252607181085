

.width-100
	width: 100%
.width-75
	width: 75%
.width-50
	width: 50%
.width-25
	width: 25%
	
	
.pad-xs
	padding:$size-xs
.pad-s
	padding:$size-s
.pad-m
	padding:$size-m
.pad-l
	padding:$size-l
.pad-xl
	padding:$size-xl
.pad-xxl
	padding:$size-xxl

.pad-top-xs
	padding-top:$size-xs
.pad-top-s
	padding-top:$size-s
.pad-top-m
	padding-top:$size-m
.pad-top-l
	padding-top:$size-l
.pad-top-xl
	padding-top:$size-xl
.pad-top-xxl
	padding-top:$size-xxl

.pad-bottom-xs
	padding-bottom:$size-xs
.pad-bottom-s
	padding-bottom:$size-s
.pad-bottom-m
	padding-bottom:$size-m
.pad-bottom-l
	padding-bottom:$size-l
.pad-bottom-xl
	padding-bottom:$size-xl
.pad-bottom-xxl
	padding-bottom:$size-xxl

.marg-xs
	margin:$size-xs
.marg-s
	margin:$size-s
.marg-m
	margin:$size-m
.marg-l
	margin:$size-l
.marg-xl
	margin:$size-xl
.marg-xxl
	margin:$size-xxl

.marg-bottom-xs
	margin-bottom:$size-xs
.marg-bottom-s
	margin-bottom:$size-s
.marg-bottom-m
	margin-bottom:$size-m
.marg-bottom-l
	margin-bottom:$size-l
.marg-bottom-xl
	margin-bottom:$size-xl
.marg-bottom-xxl
	margin-bottom:$size-xxl

.marg-left-xs
	margin-left:$size-xs
.marg-left-s
	margin-left:$size-s
.marg-left-m
	margin-left:$size-m
.marg-left-l
	margin-left:$size-l
.marg-left-xl
	margin-left:$size-xl
.marg-left-xxl
	margin-left:$size-xxl

.marg-right-xs
	margin-right:$size-xs
.marg-right-s
	margin-right:$size-s
.marg-right-m
	margin-right:$size-m
.marg-right-l
	margin-right:$size-l
.marg-right-xl
	margin-right:$size-xl
.marg-right-xxl
	margin-right:$size-xxl

