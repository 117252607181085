footer	
	nav
		ul
			padding: 0
			margin: 0
			display: flex
			justify-content: center
			li
				list-style: none
				a
					// text-decoration: underline
					color:$color-brighter
					transition: 400ms color
					&:hover
						color:$color-brightest !important