$color-brightest: #f1c8a6
$color-brighter: #d67b58
$color-bright: #c54b2d
$color-medium: #AA2611
$color-dark: #812112
$color-darker: #4e160e
$color-darkest: #1d0805
$color: $color-medium

$color-grey-brightest: #eee
$color-grey-brighter: #ddd
$color-grey-bright: #ccc
$color-grey-medium: #999
$color-grey-dark: #666
$color-grey-darker: #141414
$color-grey: $color-grey-medium

.color-brightest
	&, &:hover, &:visited, &:active
		color:$color-brightest 
.color-brighter
	&, &:hover, &:visited, &:active
		color:$color-brighter 
.color-bright
	&, &:hover, &:visited, &:active
		color:$color-bright 
.color-medium
	&, &:hover, &:visited, &:active
		color:$color-medium 
.color-dark
	&, &:hover, &:visited, &:active
		color:$color-dark 
.color-darker
	&, &:hover, &:visited, &:active
		color:$color-darker 
.color-darkest
	&, &:hover, &:visited, &:active
		color:$color-darkest 


.color-grey-brightest
	&, &:hover, &:visited, &:active
		color:$color-grey-brightest 
.color-grey-brighter
	&, &:hover, &:visited, &:active
		color:$color-grey-brighter 
.color-grey-bright
	&, &:hover, &:visited, &:active
		color:$color-grey-bright 
.color-grey-medium
	&, &:hover, &:visited, &:active
		color:$color-grey-medium 
.color-grey-dark
	&, &:hover, &:visited, &:active
		color:$color-grey-dark 
.color-grey-darker
	&, &:hover, &:visited, &:active
		color:$color-grey-darker 
// .color-grey-darkest
	// color:$color-grey-darkest 


.color-black
	color: black
.color-grey
	color: $color-grey

.color-white
	color: white

	
.bkg-color-brightest
	background-color:$color-brightest 
.bkg-color-brighter
	background-color:$color-brighter 
.bkg-color-bright
	background-color:$color-bright 
.bkg-color-medium
	background-color:$color-medium 
.bkg-color-dark
	background-color:$color-dark 
.bkg-color-darker
	background-color:$color-darker  
.bkg-color-darkest
	background-color:$color-darkest 


	
.bkg-color-black
	background-color: black 

.bkg-color-white
	background-color: white

.bkg-color-grey
	background-color: $color-grey
.bkg-color-grey-brightest
	background-color: $color-grey-brightest
	
.bkg-gradient-brighter-to-brightest
	background: linear-gradient(0deg, $color-brightest 0%, $color-brighter 100%)

.bkg-gradient-brighter-to-white
	background: linear-gradient(0deg, white 0%, $color-brighter 100%)

.bkg-gradient-white-to-brighter
	background: linear-gradient(0deg, $color-brighter 0%, white 100%)
