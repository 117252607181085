header
	background-size: cover
	background-color: $color-dark
	background-blend-mode: multiply
	.container
		@extend .center-vertically
		min-height: 35vh
		.row
			@extend .width-100
			.col
				.navbar
					padding: 0
					// *
					// 	color: pink !important
					// 	border: 1px solid green !important
					// 	display: block !important
					.navbar-collapse
						justify-content: space-between
						a.nav-link
							white-space: nowrap
							min-width: 73px
							transition: color 400ms
							color:$color-brighter !important
							text-align: right
							padding-left: $size-m !important
							padding-right: $size-m !important
							&:hover
								color:$color-brightest !important