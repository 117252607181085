body
    // font-family: open-regular, sans-serif
    font-family: "Open Sans", 'sans-serif' !important

h1, h2, h3, h4
    // font-family: raleway, sans-serif
    font-family: "Raleway", sans-serif !important

h1, h2, h3
    user-select: none
    
h1
    // margin: 0

.text-xs
    font-size: 0.8rem
.text-s
    font-size: 0.9rem

.uppercase
    text-transform: uppercase

.bold
    font-weight: bold

.text-drop-shadow
    text-shadow: 0 0 10px #000

.text-discrete
    font-size: 0.6rem
    color:#ccc


    