// smaller than lg
@include media-breakpoint-down(lg) 
    #projects .grid
        // 3 columns
        grid-template-areas: 'myArea1 myArea1 myArea2' 'myArea3 myArea4 myArea4' 'myArea3 myArea5 myArea6' 'myArea7 myArea8 myArea6' 'myArea9 myArea10 myArea10' 'myArea11 myArea12 myArea13' 'myArea14 myArea14 myArea15' 'myArea16 myArea16 myArea15  ' 


	
// larger than lg
@include media-breakpoint-up(lg) 
    #projects .grid
        // 4 columns
        grid-template-areas: 'myArea1 myArea1 myArea2 myArea3 ' 'myArea4 myArea5 myArea2 myArea6 ' 'myArea4 myArea7 myArea7 myArea8 ' 'myArea9 myArea10 myArea10 myArea10 ' 'myArea11 myArea11 myArea13 myArea14 '  'myArea15 myArea12 myArea13 myArea14 ' 'myArea15 myArea12 myArea16 myArea16  ' 
            