
a
	&, &:hover
		color:$color-medium
	&.hover-opacify
		transition: opacity 400ms
		&:hover
			opacity:0.5

main
	overflow: hidden
	


.logo
	img
		width: 200px
.round
	img
		border-radius: 100%

h1
	text-transform: uppercase
	text-align: center
	padding: 30px 0

.multiply
	mix-blend-mode: multiply

*:disabled
	cursor: not-allowed


	
.cv-pdf
	float: left
	a
		img
			width: 70px
			opacity: 0.4


.footer
	color:$color-brighter
	font-size: 0.9rem
	*
		font-family: open-light, sans-serif


// .language-javascript
// 	border: 10px solid green !important
