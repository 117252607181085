#tools
	.tools-list
		display: flex
		justify-content: center
		text-align: center
		margin-bottom: $size-xl
		img
			// @extend .width-25
		h5
			margin: 0
		p
			font-family: open-light, sans-serif
			margin: 0
			color: $color-grey-medium
		// & > div
		// 	margin-top: $size-s
		// 	margin-bottom: $size-s