#projects
	position: relative
	#projectZoomed
		position: sticky
		top: 0
		left: 0
		z-index: 9999
		img
			width: 90vw
	#projectZoomModal
		img
			width: 100%
	@media (min-width: 768px)
		.modal-xl
			max-width:60vw
.logo-clients
	.logo-client
		mix-blend-mode: multiply
		a
			opacity: 0.5
			transition: opacity 400ms
			// @extend .hover-opacify
			width: 100%
			height: 100%
			display: flex
			justify-content: center
			align-items: center
			&:hover
				opacity: 0.8







