address
	float: left

// LINKED IN CORRECT
.LI-badge-container.horizontal.light
	width: 100% !important

// .g-recaptcha
	// float: right
// 	& > div
// 		width: 100% !important

label
	display: none

.contact-form-status
	font-size: 14px