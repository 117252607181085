.mat-accordion
	&, .mat-expansion-panel
		background: transparent
	.mat-expansion-panel
		border:1px solid $color-grey-brighter

.mat-dialog-container
	position: relative
	// create cross on dialog modal
	span.close-cross
		position: absolute
		right: $size-s
		top: $size-s
		button
			&, &:active, &:hover, &:focus, &::focus, &:visited
				background: transparent
				border: 0
	.mat-dialog-content
		max-height: 77vh !important
		.img-container
			display: flex
			justify-content: center
			img
				width: 90vmin
				max-width: 100%
				height: min-intrinsic