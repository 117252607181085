.center-vertically
	display: flex
	align-items: center
	
.center
	display: flex
	justify-content: center

.flex-center
	display: flex
	align-items: center
	justify-content: center 