




// smaller than xl
@include media-breakpoint-down(xl) 

    
// larger than xl
@include media-breakpoint-up(xl)
    // *
    //     border: 3px solid green !important
    .text-not-so-wide-on-large-screen
        max-width: 70%
    #projects .grid
        // 5 columns
        grid-template-areas: 'myArea1 myArea1 myArea2 myArea3 myArea4' 'myArea5 myArea6 myArea7 myArea3 myArea8'  'myArea9 myArea10 myArea11 myArea11 myArea12'  'myArea13 myArea14 myArea15 myArea16 myArea12'  
        






