// smaller than xs
@include media-breakpoint-down(xs) 
	header
		.container
			// border: 3px solid green
			min-height: 160px



// larger than xs
@include media-breakpoint-up(xs) 